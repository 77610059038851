import React, { memo } from "react";
import { SwiperSlide } from "swiper/react"; // Ensure Swiper is installed

import CardFeature from "~/components/molecules/CardFeature"; // Update the import path as necessary
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn"; // Replace with your classnames utility

// Define the props interface
interface SlideCardProps {
  item: IProject;
  projects?: IProject[]; // Adjust based on the actual type of projects
}

const SlideCard: React.FC<SlideCardProps> = ({
  item,
  projects = [] as IProject[],
}) => {
  return (
    <SwiperSlide className="group w-full max-w-[688px]" key={item.id}>
      <div
        className={cn(
          "overflow-hidden px-3 py-[var(--padding-top-card)] transition-transform",
          projects?.length > 1
            ? "group-[&:not(.swiper-slide-active)]:-translate-y-[12%] group-[.swiper-slide-next]:-skew-y-12 group-[.swiper-slide-prev]:skew-y-12"
            : ""
        )}
      >
        <CardFeature
          project={item}
          full={false}
          unitTypeContainerClass="min-h-[132px]"
          className={cn(
            "overlay w-full max-w-[688px] hover:shadow-card-swiper-hover",
            projects?.length > 1
              ? "after:from-darkGrey40 after:to-darkGrey group-[.swiper-slide-next]:after:bg-gradient-to-r group-[.swiper-slide-prev]:after:bg-gradient-to-l"
              : ""
          )}
          thumbnailClass="max-h-[196px]"
        />
      </div>
    </SwiperSlide>
  );
};

export default memo(SlideCard);
