import isEmpty from "lodash/isEmpty";

import CardProject from "~/components/molecules/CardProject";
import ProjectTopWithGallery from "~/components/organisms/ProjectTopWithGallery";
import { ISection } from "~/entities/section";

export interface SectionProjectTopWithGalleryProps {
  data: ISection;
}

const SectionProjectTopWithGallery: React.FC<
  SectionProjectTopWithGalleryProps
> = ({ data, ...restProps }) => {
  const { id, name, slug, background, projects } = data;

  if (isEmpty(projects)) return null;

  const galleryProjects = projects.slice(1, 4);

  return (
    <section {...restProps} id={id} style={{ backgroundColor: background }}>
      <ProjectTopWithGallery className="py-8 lg:py-6" title={name} link={slug}>
        <div className="container flex flex-col gap-8 lg:hidden">
          <CardProject
            project={projects[0]}
            typeButton="long"
            layout="horizontal"
            hideDetails={false}
            bodyWrapperClass="gap-[18px]"
          />
          {Array.isArray(galleryProjects) && (
            <div className="grid grid-cols-3 gap-8">
              {galleryProjects.map((project, index) => (
                <CardProject
                  key={project.id + index}
                  project={project}
                  className="h-auto"
                />
              ))}
            </div>
          )}
        </div>

        {/*MOBILE*/}
        <div className="container hidden h-2/3 lg:block">
          <CardProject
            project={projects[0]}
            thumbnailClass="min-h-[196px]"
            typeButton="long"
            hideDetails={false}
          />
        </div>
        {galleryProjects?.length >= 1 && (
          <div className="hidden h-1/3 lg:block">
            {galleryProjects?.length === 1 && (
              <div className="container">
                <CardProject
                  full
                  className="h-full"
                  hideDetails={false}
                  showTenure={false}
                  project={galleryProjects[0]}
                />
              </div>
            )}
            {galleryProjects?.length > 1 && (
              <div className="w-full pl-6">
                <div className="scroll-padding-6 no-scrollbar -ml-6 grid snap-x snap-mandatory snap-always auto-cols-[90%] grid-flow-col grid-cols-none gap-6 overflow-x-auto px-6 py-0">
                  {galleryProjects.map((project, index) => (
                    <CardProject
                      full
                      key={project.id + index}
                      className="scroll-snap-start min-w-[90%]"
                      thumbnailClass="min-h-[136px]"
                      project={project}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </ProjectTopWithGallery>
    </section>
  );
};

export default SectionProjectTopWithGallery;
