import { useRouteLoaderData } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as z from "zod";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import { Input } from "~/components/atoms/Input";
import Typo from "~/components/atoms/Typo";
import { loader } from "~/root";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export const contactSalesSchema = z.coerce.string().email();

export interface IGreetingPromptStepInfo {
  onSubmit: (val: { phone: string; email: string }) => void;
}

const GreetingPromptStepInfo: React.FC<IGreetingPromptStepInfo> = ({
  onSubmit,
}) => {
  const refPhone = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  const loaderData = useRouteLoaderData<typeof loader>("root");
  const user =
    typeof loaderData !== "string" ? loaderData?.userConfig : undefined;

  const infoOptions: Array<{ iconType: EIcon; label: string | undefined }> = [
    { iconType: EIcon.PhoneIcon, label: user?.phone },
    { iconType: EIcon.EmailIcon, label: user?.email },
    { iconType: EIcon.WhatsAppContactIcon, label: user?.whatsapp },
  ];

  return (
    <div className="flex w-full flex-col items-center justify-between gap-6">
      <div className="flex w-full flex-row flex-nowrap justify-between gap-6 lg:flex-col">
        <div className="flex flex-[45%] flex-col flex-nowrap gap-1">
          <Typo tag="p" variant={ETypoVariant.HEADER_20} className="text-bold">
            {t("leave_your_infomation")}
          </Typo>
          <Typo tag="p" variant={ETypoVariant.SUB_TITLE_14}>
            {t("our_team_will_contact")}
          </Typo>
        </div>
        <div className="flex flex-[45%] flex-col  items-center gap-3">
          <Input
            placeholder="Phone"
            type="tel"
            ref={refPhone}
            variant="outlined"
            className="h-[43px] w-full border-gableGreen25 bg-white px-[18px] py-3"
          />
          <Input
            placeholder="Email"
            variant="outlined"
            ref={refEmail}
            className="h-[43px] w-full border-gableGreen25 bg-white px-[18px] py-3"
          />
          {!isEmpty(error) && (
            <Typo
              tag={ETypoTag.P}
              variant={ETypoVariant.BODY_TITLE_14}
              color={ETypoColor.BRICK_RED}
            >
              {error}
            </Typo>
          )}
          <Button
            className="w-36"
            onClick={() => {
              const temp = {
                phone: refPhone?.current?.value || "",
                email: refEmail?.current?.value || "",
              };
              setError("");
              const validationEmail = contactSalesSchema.safeParse(temp.email);

              if (validationEmail.success) return onSubmit(temp);

              setError(validationEmail.error.issues?.at(0)?.message || "");
            }}
          >
            {t("send")}
          </Button>
        </div>
      </div>
      <div className="divider" />
      <div className="flex w-full flex-row gap-6 lg:flex-col">
        <div className="flex-[45%]">
          <Typo tag="p" variant={ETypoVariant.HEADER_20} className="text-bold">
            {t("schedule_call")}
          </Typo>
        </div>
        <div className="flex flex-[45%] flex-col gap-6 lg:gap-3">
          {infoOptions?.map((i) =>
            i?.label ? (
              <div key={i.label} className="flex flex-row items-center gap-3">
                <Icon TypeIcon={i.iconType} width={20} height={20} />
                <Typo
                  tag="p"
                  variant={ETypoVariant.HEADER_16}
                  className="text-bold"
                >
                  {i.label}
                </Typo>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default GreetingPromptStepInfo;
