import { Input } from "~/components/atoms/Input";
import { InputProps } from "~/components/atoms/Input/type";
import Typo from "~/components/atoms/Typo";
import { ETypoVariant } from "~/utilities/enums/Typo";

export interface IGreetingPromptStep1 {
  inputProps: InputProps;
  title?: string;
}

const GreetingPromptStep1: React.FC<IGreetingPromptStep1> = ({
  title = "What can we call you?",
  inputProps,
}) => {
  return (
    <div className="flex flex-row items-center justify-between gap-12 font-bold lg:flex-col lg:items-start lg:gap-3">
      <Typo className="min-w-fit" tag="p" variant={ETypoVariant.HEADER_16}>
        {title}
      </Typo>
      <Input {...inputProps} />
    </div>
  );
};

export default GreetingPromptStep1;
