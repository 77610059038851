import ImageResponsive from "~/components/atoms/ImageResponsive";
import Link from "~/components/atoms/Link";
import { IPromotionBanner } from "~/entities/promotion";
import { cn } from "~/utilities/cn";
import { Slug } from "~/utilities/enums/Slug";
import findFirstJpegSource from "~/utilities/helpers/findFirstImage";

export interface CardBannerProps {
  promotion: IPromotionBanner;
  className?: string;
  imageClass?: string;
}

const CardBanner: React.FC<CardBannerProps> = ({
  promotion,
  className = "",
  imageClass = "",
}) => {
  return (
    <Link
      id={promotion?.id}
      to={promotion?.url ?? Slug.HOME}
      className={cn(
        "card h-full w-full cursor-pointer overflow-hidden rounded-3xl",
        className
      )}
      aria-hidden="true"
    >
      <ImageResponsive
        imageData={findFirstJpegSource(
          promotion?.photo ? [promotion?.photo] : []
        )}
        className={imageClass}
      />
    </Link>
  );
};

export default CardBanner;
