import {
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from "framer-motion";
import isEmpty from "lodash/isEmpty";
import { ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "~/components/atoms/Icons";
import Typo from "~/components/atoms/Typo";
import { IProject } from "~/entities/project";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface FeaturedLaunchesProps {
  title: string;
  projects?: IProject[];
  children?: ReactNode;
  scrollDown?: string;
}

const FeaturedLaunches: React.FC<FeaturedLaunchesProps> = ({
  title,
  projects,
  children,
  scrollDown,
}) => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();
  const [value, setValue] = useState(-40);

  const scrollProgress = useTransform(
    scrollY,
    [0, scrollRef.current?.scrollHeight || 0],
    [0, 1],
    { clamp: true }
  );

  const topPosition = useTransform(scrollProgress, [0, 1], [0, 425]);

  useMotionValueEvent(topPosition, "change", (latest) => {
    if (latest < 15) {
      setValue(-40);
      return;
    }
    if (latest === 425) {
      setValue(425);
      return;
    }
    setValue(latest - 116);
  });

  return (
    <div ref={scrollRef} className="container block pb-9 pt-8 sm:hidden">
      <Typo
        tag={ETypoTag.DIV}
        variant={ETypoVariant.HEADER_32}
        className="mb-6 break-words font-bold md:text-header-20"
      >
        <div
          dangerouslySetInnerHTML={{ __html: title }}
          className="ql-editor"
        />
      </Typo>

      {!isEmpty(projects) && (
        <div className="relative">
          <div dir="rtl" className="scroll h-[496px] overflow-y-auto ">
            <div
              dir="ltr"
              className="mx-auto grid max-w-688 grid-cols-1 gap-y-8 py-6"
            >
              {children}
            </div>
          </div>
          <motion.div
            initial={{
              opacity: 0,
              position: "absolute",
              top: value + "px",
              right: "0",
            }}
            whileInView={{ top: value + "px", opacity: 1 }}
            className="-mr-4 flex -rotate-90 flex-row-reverse items-center gap-x-3 lg:-mr-16"
            transition={{
              type: "spring",
              damping: 20,
              mass: 0.1,
              stiffness: 100,
              restDelta: 0.0001,
            }}
          >
            <Typo
              className="inline-block font-bold"
              color={ETypoColor.TEXT}
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_16}
            >
              {scrollDown ?? t("scroll_down")}
            </Typo>
            <div className="rotate-90">
              <Icon
                TypeIcon={EIcon.ScrollDownIcon}
                className="animate-scrollDown"
                height={40}
                width={20}
              />
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default FeaturedLaunches;
