import SlideCards from "~/components/organisms/SlideCards";
import SlideCardsMobile from "~/components/organisms/SlideCardsMobile";
import { ISection } from "~/entities/section";
import { Slug } from "~/utilities/enums/Slug";
export interface SectionSlideCardsProps {
  data: ISection;
}

const SectionSlideCards: React.FC<SectionSlideCardsProps> = ({
  data,
  ...restProps
}) => {
  const { id, name, slug, background, projects } = data;

  return (
    <section {...restProps} id={id} style={{ backgroundColor: background }}>
      <div className="block py-12 sm:hidden">
        <SlideCards name={name} link={slug} projects={projects} />
      </div>

      {/* MOBILE VIEW */}
      <div className="hidden py-6 sm:block">
        <SlideCardsMobile
          projects={projects}
          name={name}
          link={Slug.PROJECTS + slug}
        />
      </div>
    </section>
  );
};

export default SectionSlideCards;
