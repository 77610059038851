import { ReactNode } from "react";

import Typo from "~/components/atoms/Typo";
import LinkViewMore from "~/components/molecules/LinkViewMore";
import { cn } from "~/utilities/cn";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface GallerySixProps {
  children?: ReactNode;
  className?: string;
  title: string;
  link: string;
}

const GallerySix: React.FC<GallerySixProps> = (props) => {
  const { children, className, title, link } = props;
  return (
    <div className={cn("flex flex-col gap-8 lg:gap-6", className)}>
      <div className="container flex items-center justify-between gap-6">
        <Typo
          tag={ETypoTag.DIV}
          variant={ETypoVariant.HEADER_32}
          className="break-words font-bold md:text-header-20"
        >
          <div
            dangerouslySetInnerHTML={{ __html: title }}
            className="ql-editor"
          />
        </Typo>
        <LinkViewMore slug={link} className="lg:hidden" />
      </div>
      {children}
      <LinkViewMore
        slug={link}
        className="container hidden justify-center lg:flex"
      />
    </div>
  );
};

export default GallerySix;
