import isEmpty from "lodash/isEmpty";

import Typo from "~/components/atoms/Typo";
import SwiftCards from "~/components/organisms/SwiftCards";
import SwiftCardsMobile from "~/components/organisms/SwiftCardsMobile";
import { ISection } from "~/entities/section";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SectionSwiftCardsProps {
  data: ISection;
}

const SectionSwiftCards: React.FC<SectionSwiftCardsProps> = ({
  data,
  ...restProps
}) => {
  const { id, name, slug, background, projects } = data;
  return (
    <section {...restProps} id={id} style={{ backgroundColor: background }}>
      <div className="wrapper block py-8 lg:hidden">
        <Typo
          tag={ETypoTag.DIV}
          variant={ETypoVariant.HEADER_32}
          className="break-words font-bold md:text-header-20"
        >
          <div
            dangerouslySetInnerHTML={{ __html: name }}
            className="ql-editor"
          />
        </Typo>
        {!isEmpty(projects) && <SwiftCards link={slug} projects={projects} />}
      </div>
      {/* MOBILE */}
      <div className="hidden py-6 lg:block">
        <SwiftCardsMobile
          projects={projects}
          name={name}
          link={Slug.PROJECTS + slug}
        />
      </div>
    </section>
  );
};

export default SectionSwiftCards;
