import isEmpty from "lodash/isEmpty";
import { ReactNode } from "react";

import Typo from "~/components/atoms/Typo";
import { IProject } from "~/entities/project";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface FeaturedLaunchesMobileProps {
  title: string;
  projects?: IProject[];
  children?: ReactNode;
}

const FeaturedLaunchesMobile = ({
  title,
  projects,
  children,
}: FeaturedLaunchesMobileProps) => {
  return (
    <div className="container hidden flex-col gap-5 pb-6 lg:flex">
      <Typo
        tag={ETypoTag.DIV}
        variant={ETypoVariant.HEADER_32}
        className="break-words font-bold md:text-header-20"
      >
        <div
          dangerouslySetInnerHTML={{ __html: title }}
          className="ql-editor"
        />
      </Typo>
      {!isEmpty(projects) && (
        <div className="no-scrollbar -mb-6 flex h-[572px] flex-col gap-5 overflow-y-auto overflow-x-hidden">
          {children}
        </div>
      )}
    </div>
  );
};

export default FeaturedLaunchesMobile;
