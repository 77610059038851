import { useTranslation } from "react-i18next";

import IconChevronRight from "~/components/atoms/Icons/IconChevronRight";
import Link from "~/components/atoms/Link";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface LinkViewMoreProps {
  slug: string;
  className?: string;
}

const LinkViewMore: React.FC<LinkViewMoreProps> = ({
  slug,
  className = "",
}) => {
  const { t } = useTranslation();
  return (
    <Link
      to={slug || Slug.PROJECTS}
      className={cn("group inline-flex items-center justify-center", className)}
    >
      <Typo
        tag={ETypoTag.SPAN}
        variant={ETypoVariant.BODY_TITLE_16}
        color={ETypoColor.TEXT}
        className="whitespace-nowrap font-bold"
      >
        {t("view_more")}
      </Typo>
      {/* <Icon
        className="mb-[-3px] transition-all duration-500 group-hover:translate-x-1"
        TypeIcon={EIcon.ChevronRightIcon}
        height={20}
        width={20}
      /> */}
      <IconChevronRight className="-mb-[3px] transition-all duration-500 group-hover:translate-x-1" />
    </Link>
  );
};

export default LinkViewMore;
