import { useState } from "react";
import { Button } from "react-daisyui";
import { useTranslation } from "react-i18next";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import GreetingModal from "~/components/organisms/GreetingModal";
import { ISection } from "~/entities/section";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface ISectionCTAProps {
  wrapperClass?: string;
  data: ISection;
}

const SectionCTA: React.FC<ISectionCTAProps> = ({
  data,
  wrapperClass = "",
}) => {
  const { t } = useTranslation();
  const [openGreeting, setOpenGreeting] = useState(false);
  return (
    <>
      <section className={cn("py-8 lg:py-6", wrapperClass)}>
        <div className="container relative lg:flex lg:flex-col lg:gap-6">
          <div className="absolute left-[40%] right-0 top-0 w-[60%] xxl:w-[calc(60%-28px)] lg:static lg:w-full">
            <div className="relative flex items-end gap-12 rounded-bl-3xl bg-background pb-8 pl-12 lg:flex-col lg:items-start lg:gap-6 lg:pb-0 lg:pl-0">
              <div className="after:contents[''] absolute -left-6 top-0 h-6 w-6 overflow-hidden after:absolute after:left-0 after:top-0 after:h-full after:w-full after:rounded-tr-full after:shadow-cta-bottom lg:hidden" />
              <div className="flex flex-1 flex-col gap-3">
                <Typo
                  tag={ETypoTag.H4}
                  variant={ETypoVariant.HEADER_36}
                  color={ETypoColor.TEXT}
                  className="font-bold lg:text-header-36"
                >
                  {data?.cta?.title || ""}
                </Typo>
                <Typo
                  tag={ETypoTag.P}
                  variant={ETypoVariant.BODY_TITLE_16}
                  color={ETypoColor.TEXT}
                  className="opacity-75 lg:text-body-title-16"
                >
                  {data?.cta?.description || ""}
                </Typo>
              </div>
              <Button
                type="button"
                className={cn(
                  "lg w-[201px] !rounded-[21.5px] !border !border-solid px-0 py-3 text-header-16 font-bold lg:text-header-16",
                  "border-color !bg-color capitalize !text-white disabled:pointer-events-auto"
                )}
                onClick={() => setOpenGreeting(true)}
              >
                {data?.cta?.button?.title}
              </Button>
              <div className="after:contents[''] absolute -bottom-6 right-0 h-6 w-6 overflow-hidden after:absolute after:left-0 after:top-0 after:h-full after:w-full after:rounded-tr-full after:shadow-cta-bottom lg:hidden" />
            </div>
          </div>
          <ImageResponsive
            imageData={data?.photo}
            className="aspect-1280/325 h-auto w-full rounded-3xl lg:aspect-327/200 lg:rounded-xl lg:object-center"
          />
        </div>
      </section>
      <GreetingModal
        open={openGreeting}
        onClose={() => setOpenGreeting(false)}
      />
    </>
  );
};

export default SectionCTA;
