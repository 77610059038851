import CardProject from "~/components/molecules/CardProject";
import GallerySix from "~/components/organisms/GallerySix";
import { IProject } from "~/entities/project";
import { ISection } from "~/entities/section";

export interface SectionGallerySixProps {
  data: ISection;
}

const SectionGallerySix: React.FC<SectionGallerySixProps> = ({
  data,
  ...restProps
}) => {
  const { id, name, slug, background, projects } = data;

  if (!projects) return null;

  const galleryProjects: IProject[] = projects.slice(0, 6);

  return (
    <section {...restProps} id={id} style={{ backgroundColor: background }}>
      <GallerySix className="py-8 lg:py-6" title={name} link={slug}>
        <div className="container grid grid-cols-3 gap-8 lg:auto-rows-auto lg:grid-cols-1 lg:gap-6">
          {galleryProjects.map((project: IProject) => (
            <CardProject
              key={project.id}
              project={project}
              layout="vertical"
              hideDetails={true}
              className="h-auto"
            />
          ))}
        </div>
      </GallerySix>
    </section>
  );
};

export default SectionGallerySix;
