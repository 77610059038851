import Typo from "~/components/atoms/Typo";
import LinkViewAll from "~/components/molecules/LinkViewAll";
import { cn } from "~/utilities/cn";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface ProjectTopWithGalleryProps {
  children?: React.ReactNode;
  className?: string;
  link: string;
  title: string;
}

const ProjectTopWithGallery: React.FC<ProjectTopWithGalleryProps> = ({
  children,
  ...props
}) => {
  const { className, link, title } = props;
  return (
    <div className={cn("flex flex-col gap-8 lg:gap-6", className)}>
      <div className="container flex items-center justify-between gap-6">
        <Typo
          tag={ETypoTag.DIV}
          variant={ETypoVariant.HEADER_32}
          className="break-words font-bold md:text-header-20"
        >
          <div
            dangerouslySetInnerHTML={{ __html: title }}
            className="ql-editor"
          />
        </Typo>
        <LinkViewAll slug={link} className="flex lg:hidden" />
      </div>
      {children}
      <LinkViewAll slug={link} className="hidden lg:flex" />
    </div>
  );
};

export default ProjectTopWithGallery;
