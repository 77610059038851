import { useFetcher, useRouteLoaderData } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-daisyui";
import { useTranslation } from "react-i18next";

import { TSubmitGreetingData } from "./type";

import Button from "~/components/atoms/Button";
import IconClose from "~/components/atoms/Icons/IconClose";
import Typo from "~/components/atoms/Typo";
import DrawerRange from "~/components/molecules/Drawer/DrawerRange/DrawerRange";
import GreetingPromptStep1 from "~/components/molecules/GreetingPromptSteps/GreetingPromptStep1";
import GreetingPromptStep2 from "~/components/molecules/GreetingPromptSteps/GreetingPromptStep2";
import GreetingPromptStep3 from "~/components/molecules/GreetingPromptSteps/GreetingPromptStep3";
import GreetingPromptStepInfo from "~/components/molecules/GreetingPromptSteps/GreetingPromptStepInfo";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { cn } from "~/utilities/cn";
import { EFormType } from "~/utilities/enums/FormType";
import { ETypoVariant } from "~/utilities/enums/Typo";
import { EUserPreferenceType } from "~/utilities/enums/UserPreferenceType";

const TOTAL_STEPS = ["welcome", "location", "type", "budget", "info"] as const;
type TStep = (typeof TOTAL_STEPS)[number];

const HIDE_STEPER: TStep[] = ["welcome", "info"];

const defaultRefValue = {
  locationSelected: [],
  unitTypeSelected: [],
  range: {
    from: "0",
    to: "0",
  },
  phone: "",
  email: "",
};

interface IGreetingModalProps {
  open: boolean;
  onClose: () => void;
}

const GreetingModal: React.FC<IGreetingModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [curStep, setCurStep] = useState<number>(0);
  const [name, setName] = useState("");

  const valueRef = useRef<{
    locationSelected: string[];
    unitTypeSelected: string[];
    range: {
      from: string;
      to: string;
    };
    phone: string;
    email: string;
  }>(defaultRefValue);

  const postRequest = useFetcher();
  postRequest.formAction = "/";

  const { filterOptions } = useRouteLoaderData("root") as IRouteLoaderData;
  const locationOptions = filterOptions?.locations;
  const unitTypeOptions = filterOptions?.unitTypes?.slice(0, 6);

  useEffect(() => {
    if (!open) {
      setCurStep(0);
      setName("");
      valueRef.current = defaultRefValue;
    }
  }, [open]);

  const submitData: TSubmitGreetingData = {
    name,
    email: valueRef.current.email,
    phone: valueRef.current.phone,
    options: [
      {
        preferenceType: EUserPreferenceType.Location,
        data: valueRef.current.locationSelected,
      },
      {
        preferenceType: EUserPreferenceType.UnitType,
        data: valueRef.current.unitTypeSelected,
      },
      {
        preferenceType: EUserPreferenceType.Range,
        data: {
          from: Number(valueRef.current.range.from),
          to: Number(valueRef.current.range.to),
        },
      },
    ],
  };

  const onFilterGreeting = async (values: TSubmitGreetingData) => {
    if (typeof window === "undefined") return;
    const searchParams = new URLSearchParams();
    values.options.forEach((o) => {
      if (
        o.preferenceType === EUserPreferenceType.Location &&
        Array.isArray(o.data)
      ) {
        o.data.forEach((d) => {
          searchParams.append("location[]", d);
        });
      }
      if (
        o.preferenceType === EUserPreferenceType.UnitType &&
        Array.isArray(o.data)
      ) {
        o.data.forEach((d) => {
          searchParams.append("unitType[]", d);
        });
      }
      if (
        o.preferenceType === EUserPreferenceType.Range &&
        !Array.isArray(o.data)
      ) {
        searchParams.append("minPrice", String(o.data.from));
        searchParams.append("maxPrice", String(o.data.to));
      }
    });

    // submit greeting data
    if (isEmpty(values?.email)) return;
    await postRequest.submit(
      { type: EFormType.GREETING_FORM, values },
      {
        action: "/",
        method: "POST",
        encType: "application/json",
      }
    );
  };

  const onNext = () => {
    setCurStep((pre) => {
      const newStep = pre + 1;
      if (newStep > TOTAL_STEPS.length - 1) {
        onFilterGreeting(submitData);
        return pre;
      }
      return newStep;
    });
  };

  const STEP_SETTING: Record<
    TStep,
    { component: React.ReactNode; title?: string; containerClass: string }
  > = {
    welcome: {
      component: (
        <GreetingPromptStep1
          inputProps={{
            placeholder: "name",
            className:
              "w-full h-[43px] bg-white px-[18px] py-3 border-gableGreen25",
            variant: "outlined",
            value: name,
            onChange: (e) => setName(e.target.value),
          }}
        />
      ),
      title: t("let_us_help_you"),
      containerClass: "",
    },
    location: {
      component: (
        <GreetingPromptStep2
          options={locationOptions}
          onChecked={(newVal) => {
            valueRef.current = {
              ...valueRef.current,
              locationSelected: newVal,
            };
          }}
        />
      ),
      title: t("you_is_looking_for", { name: isEmpty(name) ? "You" : name }),
      containerClass: "",
    },
    type: {
      component: (
        <GreetingPromptStep3
          options={unitTypeOptions}
          onChecked={(values) => {
            valueRef.current = {
              ...valueRef.current,
              unitTypeSelected: values,
            };
          }}
        />
      ),
      title: t("select_type_you_want", { name: isEmpty(name) ? "You" : name }),
      containerClass: "max-w-754 lg:max-w-100%",
    },
    budget: {
      component: (
        <DrawerRange
          fromValue=""
          toValue=""
          onChangeValue={(value: { from: string; to: string }) => {
            valueRef.current = {
              ...valueRef.current,
              range: value,
            };
          }}
          containerClassname="flex-row flex-nowrap"
        />
      ),
      title: t("you_budget", { name: isEmpty(name) ? "Your" : `${name} ` }),
      containerClass: "",
    },
    info: {
      component: (
        <GreetingPromptStepInfo
          onSubmit={(val) => {
            valueRef.current = {
              ...valueRef.current,
              phone: val.phone,
              email: val.email,
            };
            onFilterGreeting({
              ...submitData,
              email: val.email,
              phone: val.phone,
            });
          }}
        />
      ),
      title: undefined,
      containerClass: "max-w-754 lg:max-w-100%",
    },
  };

  const component = STEP_SETTING[TOTAL_STEPS[curStep]]?.component;
  const title = STEP_SETTING[TOTAL_STEPS[curStep]]?.title;
  const buttonLabel = curStep === TOTAL_STEPS.length - 1 ? "Done" : "Next";
  const showAction = TOTAL_STEPS[curStep] !== "info";
  const isHideSteper = !HIDE_STEPER.includes(TOTAL_STEPS[curStep]);
  const modalClass = STEP_SETTING[TOTAL_STEPS[curStep]]?.containerClass;

  return (
    <>
      <Modal
        className={cn("w-full lg:max-h-[90dvh] lg:w-11/12 ", modalClass)}
        backdrop={true}
        open={open}
      >
        <Modal.Header className="font-bold">
          <div className="flex flex-row justify-between">
            <Typo
              variant={ETypoVariant.HEADER_32}
              className="lg:text-sub-title-20"
              tag="h1"
            >
              {title || ""}
            </Typo>
            <IconClose
              onClick={() => {
                onFilterGreeting(submitData);
                onClose();
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {isHideSteper ? (
            <ul className="steps mb-12 w-full">
              {TOTAL_STEPS.filter((n) => !HIDE_STEPER.includes(n)).map(
                (name, indx) => (
                  <li
                    key={name}
                    className={cn("step", curStep > indx ? "step-primary" : "")}
                  ></li>
                )
              )}
            </ul>
          ) : (
            <></>
          )}
          <div className="overflow-hidden">{component}</div>
          {showAction && (
            <Modal.Actions className="flex flex-col items-center justify-center gap-12">
              <Button
                className="border-none bg-teal text-white hover:bg-teal active:bg-teal"
                onClick={onNext}
              >
                {buttonLabel}
              </Button>
            </Modal.Actions>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GreetingModal;
