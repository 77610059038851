import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "~/components/atoms/Checkbox";
import { Input } from "~/components/atoms/Input";
import { SelectOptions } from "~/components/atoms/SelectBox/MultiSelect";
import { cn } from "~/utilities/cn";

export interface IGreetingPromptStep2 {
  onChecked: (values: string[]) => void;
  options: SelectOptions[];
}

const GreetingPromptStep2: React.FC<IGreetingPromptStep2> = ({
  onChecked,
  options,
}) => {
  const { t } = useTranslation();
  const [localOptions, setLocalOptions] = useState<SelectOptions[]>([]);
  const [checkedList, setCheckedList] = useState<Array<string>>([]);
  const [searchKey, setSearchKey] = useState("");

  const filterOptions = localOptions?.filter((l) => l.name.includes(searchKey));

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  return (
    <div className="flex flex-col items-center justify-between gap-6">
      <Input
        type="search"
        variant="outlined"
        className="h-[43px] w-full border-gableGreen25 bg-white px-[18px] py-3"
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        placeholder={t("search_location")}
      />
      <div className="scroll flex max-h-[270px] w-full flex-col items-center justify-between gap-3 overflow-auto">
        {filterOptions?.map(({ id, name }) => {
          const isActive = checkedList.includes(String(id));
          return (
            <div
              key={id}
              className={cn(
                "w-full cursor-pointer rounded-[21.5px]  px-[18px] py-3",
                isActive ? "bg-mediumTurquoise" : "bg-gableGreen5"
              )}
              onClick={() => {
                const newValues = isActive
                  ? checkedList?.filter((c) => c !== id)
                  : [...checkedList, id];
                setCheckedList(newValues);
                onChecked(newValues);
              }}
            >
              <Checkbox
                color="secondary"
                label={name}
                className="w-full"
                checked={isActive}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GreetingPromptStep2;
