import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "~/components/atoms/Checkbox";
import { SelectOptions } from "~/components/atoms/SelectBox/MultiSelect";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoVariant } from "~/utilities/enums/Typo";

export interface IGreetingPromptStep3 {
  onChecked: (values: string[]) => void;
  options: SelectOptions[];
}

const GreetingPromptStep3: React.FC<IGreetingPromptStep3> = ({
  onChecked,
  options,
}) => {
  const { t } = useTranslation();
  const [localOptions, setLocalOptions] = useState<SelectOptions[]>([]);
  const [checkedList, setCheckedList] = useState<Array<string>>([]);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  return (
    <div className="flex flex-col items-center justify-between gap-6">
      <Typo tag="p" variant={ETypoVariant.SUB_TITLE_16} className="color-color">
        {t("allowed_to_choose")}
      </Typo>
      <div className="flex max-h-[270px] w-full flex-row flex-wrap items-center justify-between gap-6 overflow-auto lg:flex-col lg:flex-nowrap">
        {localOptions?.map(({ id, name }) => {
          const isActive = checkedList.includes(String(id));
          return (
            <button
              key={id}
              className={cn(
                "flex-[1 1 22%] min-w-[200px] cursor-pointer rounded-[21.5px] px-[18px] py-3 lg:min-w-full",
                isActive ? "bg-mediumTurquoise" : "bg-gableGreen5"
              )}
              onClick={() => {
                const newValues = isActive
                  ? checkedList?.filter((c) => c !== id)
                  : [...checkedList, id];
                setCheckedList(newValues);
                onChecked(newValues);
              }}
            >
              <Checkbox
                color="secondary"
                label={name}
                className="w-full"
                checked={isActive}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default GreetingPromptStep3;
