import { Link as RemixLink } from "@remix-run/react";

interface LinkProps {
  id?: string;
  to: string;
  children: React.ReactNode;
  className?: string;
}

const Link: React.FC<LinkProps> = ({ id, to, children, className }) => {
  // Check if the link is internal
  const isInternal = /^\/(?!\/)/.test(to);

  if (isInternal) {
    // Use Remix Link for internal links
    return (
      <RemixLink id={id} to={to} className={className}>
        {children}
      </RemixLink>
    );
  } else {
    // Use <a> for external links
    return (
      <a
        id={id}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  }
};

export default Link;
