import FeaturedLaunchesMobile from "app/components/organisms/FeaturedLaunchesMobile";

import CardFeature from "~/components/molecules/CardFeature";
import FeaturedLaunches from "~/components/organisms/FeaturedLaunches";
import { ISection } from "~/entities/section";

export interface SectionFeaturedLaunchesProps {
  data?: ISection;
}

const SectionFeaturedLaunches: React.FC<SectionFeaturedLaunchesProps> = ({
  data,
}) => {
  const { background, name = "", projects } = { ...data };

  if (!data) return <></>;

  return (
    <section style={{ backgroundColor: background }}>
      <FeaturedLaunches title={name} projects={projects}>
        {projects?.map((project, index) => (
          <CardFeature
            key={project.id + index}
            project={project}
            className="mx-5"
            thumbnailClass="max-h-[196px]"
          />
        ))}
      </FeaturedLaunches>

      {/* Mobile */}
      <FeaturedLaunchesMobile title={name} projects={projects}>
        {projects?.map((project, index) => (
          <CardFeature
            key={project.id + index}
            project={project}
            className="last:mb-6"
            thumbnailClass="max-h-[176px]"
          />
        ))}
      </FeaturedLaunchesMobile>
    </section>
  );
};

export default SectionFeaturedLaunches;
