import { useMemo } from "react";

import CardProject from "~/components/molecules/CardProject";
import GalleryThree from "~/components/organisms/GalleryThree";
import { IProject } from "~/entities/project";
import { ISection } from "~/entities/section";
import { cn } from "~/utilities/cn";

export interface SectionGalleryThreeProps {
  data: ISection;
}

const SectionGalleryThree: React.FC<SectionGalleryThreeProps> = ({
  data,
  ...restProps
}) => {
  const { id, name, slug, background, projects } = data;

  const galleryProjects: IProject[] = useMemo(() => {
    return projects.slice(0, 3);
  }, [projects]);

  if (!projects) return <></>;

  return (
    <>
      {galleryProjects?.length > 0 && (
        <section {...restProps} id={id} style={{ backgroundColor: background }}>
          <GalleryThree
            className="py-8 lg:py-6"
            title={name}
            link={slug}
            projects={galleryProjects}
          >
            <div className="container grid grid-cols-3 gap-8 lg:hidden">
              {galleryProjects.map((project: IProject, index: number) => (
                <CardProject
                  isShownCompareButton={true}
                  key={project.id + index}
                  project={project}
                  className="h-auto"
                />
              ))}
            </div>
            <div className="hidden w-full pl-6 lg:block">
              <div
                className={cn(
                  `scroll-padding-6 no-scrollbar -ml-6 grid snap-x snap-mandatory snap-always grid-flow-col grid-cols-none gap-6 overflow-x-auto px-6 py-0`,
                  galleryProjects.length > 1
                    ? "auto-cols-[90%]"
                    : "auto-cols-[100%]"
                )}
              >
                {galleryProjects.map((project, index) => (
                  <CardProject
                    full
                    isShownCompareButton={true}
                    key={project?.id + index}
                    className="scroll-snap-start min-w-[90%]"
                    project={project}
                  />
                ))}
              </div>
            </div>
          </GalleryThree>
        </section>
      )}
    </>
  );
};

export default SectionGalleryThree;
