import React from "react";

import { cn } from "~/utilities/cn";

interface IconDoubleChevronRightProps {
  className?: string; // Optional className to pass Tailwind CSS styles
}

const IconDoubleChevronRight: React.FC<IconDoubleChevronRightProps> = ({
  className,
}) => {
  return (
    <svg
      className={cn(
        "h-7 w-7 text-color", // Default size and color
        className // Additional class names passed via props
      )}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 16l4-4-4-4m6 8l4-4-4-4"
      />
    </svg>
  );
};

export default IconDoubleChevronRight;
