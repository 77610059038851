import { useRef, useState } from "react";

import CardFeature from "~/components/molecules/CardFeature";
import CardProject from "~/components/molecules/CardProject";
import ProjectWithScrollGallery from "~/components/organisms/ProjectWithScrollGallery";
import { ISection } from "~/entities/section";
import useWindowResize from "~/hooks/use-window-resize";

export interface SectionProjectWithScrollGalleryProps {
  data: ISection;
}

const SectionProjectWithScrollGallery: React.FC<
  SectionProjectWithScrollGalleryProps
> = ({ data }) => {
  const { id, name, slug, background, projects } = data;
  const firstColRef = useRef<HTMLDivElement>(null);
  const [secondColHeight, setSecondColHeight] = useState<string>("auto");
  const windowSize = useWindowResize();

  const scrollProjects = projects.slice(1);

  // useEffect(() => {
  //   if (firstColRef.current) {
  //     setSecondColHeight(`${firstColRef.current.offsetHeight | 0}px`);
  //   }
  // }, [windowSize]);

  return (
    <section id={id} style={{ backgroundColor: background }}>
      <ProjectWithScrollGallery
        className="py-8 lg:py-6"
        title={name}
        link={slug}
      >
        <>
          {projects?.length >= 1 && (
            <div className="grid-auto-rows container grid auto-rows-min grid-cols-3 gap-5 lg:hidden">
              <div className="relative col-span-2 mr-2">
                <div
                  ref={firstColRef}
                  className="after:content[''] after:absolute after:-right-6 after:top-0 after:h-full after:w-px after:bg-color after:opacity-25"
                >
                  <CardFeature
                    full
                    project={projects[0]}
                    thumbnailClass="max-h-[364px]"
                  />
                </div>
              </div>
              <div className="scroll col-span-1 block overflow-y-auto">
                <div
                  className="flex flex-col gap-y-8"
                  style={{ maxHeight: `max(${secondColHeight} , 521px)` }}
                >
                  {scrollProjects.map((data, index) => (
                    <CardProject
                      key={data?.id + index}
                      className="mx-5"
                      project={data}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </>

        {/*MOBILE*/}
        {projects?.length >= 1 && (
          <>
            <div className="container hidden h-2/3 lg:block">
              <CardFeature
                full
                project={projects[0]}
                thumbnailClass="max-h-[364px]"
              />
            </div>
            <div className="hidden h-1/3 lg:block">
              {projects?.length === 1 && (
                <div className="container">
                  <CardProject
                    full
                    className="h-full"
                    hideDetails={false}
                    showTenure={false}
                    project={projects[0]}
                  />
                </div>
              )}
              {projects?.length > 1 && (
                <div className="w-full pl-6">
                  <div className="scroll-padding-6 no-scrollbar -ml-6 grid snap-x snap-mandatory snap-always auto-cols-[90%] grid-flow-col grid-cols-none gap-6 overflow-x-auto px-6 py-0">
                    {projects.map((project, index) => (
                      <CardProject
                        full
                        key={project.id + index}
                        className="scroll-snap-start min-w-[90%]"
                        thumbnailClass="min-h-[136px]"
                        project={project}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </ProjectWithScrollGallery>
    </section>
  );
};

export default SectionProjectWithScrollGallery;
