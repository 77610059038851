import CardProject from "~/components/molecules/CardProject";
import ProjectLeftWithGallery from "~/components/organisms/ProjectLeftWithGallery";
import { IProject } from "~/entities/project";
import { ISection } from "~/entities/section";

export interface SectionProjectLeftWithGalleryProps {
  data: ISection;
}

const SectionProjectLeftWithGallery: React.FC<
  SectionProjectLeftWithGalleryProps
> = ({ data, ...restProps }) => {
  const { id, name, slug, background, projects } = data;

  const galleryProjects: IProject[] = projects.slice(1, 5);

  return (
    <section {...restProps} id={id} style={{ backgroundColor: background }}>
      <ProjectLeftWithGallery className="py-8 lg:py-6" title={name} slug={slug}>
        <div className="container grid grid-cols-4 grid-rows-2 gap-8 lg:hidden">
          <div className="col-span-2 row-span-2 flex items-center justify-center">
            <CardProject
              full={true}
              layout="vertical"
              className="h-full"
              thumbnailClass="min-h-[196px]"
              hideDetails={false}
              showTenure={false}
              project={projects[0]}
            />
          </div>
          {Array.isArray(galleryProjects) &&
            galleryProjects.map((project) => (
              <div
                key={project.id}
                className="col-span-1 flex h-full items-center justify-center xxl:col-span-2"
              >
                <CardProject
                  thumbnailClass="max-w-full max-h-full"
                  className="h-full w-full"
                  project={project}
                />
              </div>
            ))}
        </div>

        {/*MOBILE*/}
        <div className="container hidden h-2/3 lg:block">
          <CardProject
            full={true}
            layout="vertical"
            className="h-full"
            hideDetails={false}
            showTenure={false}
            project={projects[0]}
          />
        </div>
        {galleryProjects?.length >= 1 && (
          <div className="hidden h-1/3 lg:block">
            {galleryProjects?.length === 1 && (
              <div className="container">
                <CardProject
                  full={true}
                  layout="vertical"
                  className="h-full"
                  hideDetails={false}
                  showTenure={false}
                  project={galleryProjects[0]}
                />
              </div>
            )}
            {galleryProjects?.length > 1 && (
              <div className="w-full pl-6">
                <div className="scroll-padding-6 no-scrollbar -ml-6 grid snap-x snap-mandatory snap-always auto-cols-[90%] grid-flow-col grid-cols-none gap-6 overflow-x-auto px-6 py-0">
                  {galleryProjects.map((project, index) => (
                    <CardProject
                      full
                      key={project.id + index}
                      className="scroll-snap-start min-w-[90%]"
                      thumbnailClass="min-h-[136px]"
                      project={project}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </ProjectLeftWithGallery>
    </section>
  );
};

export default SectionProjectLeftWithGallery;
