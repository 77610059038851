import Typo from "~/components/atoms/Typo";
import { ISection } from "~/entities/section";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SectionHTMLContentProps {
  data: ISection;
}

const SectionHTMLContent: React.FC<SectionHTMLContentProps> = ({ data }) => {
  const { id, name, background, html } = data;

  if (!html) return null;

  return (
    <section id={id} style={{ backgroundColor: background }}>
      <div className="container flex flex-col gap-8 py-8 lg:gap-6 lg:py-6">
        <Typo
          tag={ETypoTag.DIV}
          variant={ETypoVariant.HEADER_32}
          className="break-words font-bold md:text-header-20"
        >
          <div
            dangerouslySetInnerHTML={{ __html: name }}
            className="ql-editor"
          />
        </Typo>
        <div
          className="ql-editor"
          data-name="SectionHTMLContent"
          id={id}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </section>
  );
};

export default SectionHTMLContent;
