import isEmpty from "lodash/isEmpty";
import { SwiperOptions } from "swiper/types";

import SlideCard from "./SlideCard";

import { Swiper } from "~/components/atoms/Swiper";
import Typo from "~/components/atoms/Typo";
import LinkViewAll from "~/components/molecules/LinkViewAll";
import { IProject } from "~/entities/project";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SlideCardsProps {
  projects: IProject[];
  name: string;
  link: string;
}

export default function SlideCards({ projects, name, link }: SlideCardsProps) {
  const swiperOptions: SwiperOptions = {
    slidesPerView: "auto",
    centeredSlides: true,
    initialSlide: projects?.length > 1 ? 1 : 0,
    pagination: {
      clickable: true,
      el: ".swiper-pagination-swiper__slideCards",
    },
  };

  return (
    <div
      style={
        {
          "--padding-top-card": "90px",
        } as React.CSSProperties
      }
      className="swiper__slideCards"
    >
      <div className="container mb-6 flex items-center justify-between">
        <Typo
          tag={ETypoTag.DIV}
          variant={ETypoVariant.HEADER_32}
          className="break-words font-bold md:text-header-20"
        >
          <div
            dangerouslySetInnerHTML={{ __html: name }}
            className="ql-editor"
          />
        </Typo>
        <LinkViewAll slug={link} />
      </div>
      <Swiper className="-mb-[var(--padding-top-card)]" {...swiperOptions}>
        {projects && !isEmpty(projects) ? (
          projects.map((item: IProject) => (
            <SlideCard key={item?.id} item={item} projects={projects} />
          ))
        ) : (
          <p className="text-gray-500">No projects available</p>
        )}
      </Swiper>
      {/* PAGINATION */}
      <div className="swiper-pagination-swiper__slideCards relative z-[50] mt-6 flex w-full justify-center gap-x-3">
        {/* Pagination bullets */}
      </div>
    </div>
  );
}
